import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/3371524.jpg";
import projImg2 from "../assets/img/SL-052622-50620-13.jpg";
import projImg3 from "../assets/img/concepto-negocio-holografia-grafica.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Calculator",
      description: "VueJS calculator",
      imgUrl: projImg1,
      link: "https://github.com/Ac7Andres/calculator-app",
    },
    {
      title: "Counter",
      description: "React counter",
      imgUrl: projImg2,
      link: "https://github.com/Ac7Andres/ContadorReact",
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
      link: "https://github.com/Ac7Andres/WebDevelopment-company",
    },
  ];
  
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Projects</h2>
                  <p>Explore a compilation of my academic endeavors in web development. While these projects were crafted within an educational setting, they reflect my foundational skills, enthusiasm for learning, and dedication to mastering the craft. Each project represents a step in my journey towards becoming a proficient web developer.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Upcoming</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Upcoming</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Upcoming</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        
                      </Tab.Pane>
                      <Tab.Pane eventKey="section">
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}